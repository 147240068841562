<template>
  <div>
    <NavigationBar titel="Archiv" isPreview></NavigationBar>
    <section>
      <TippCard
        hintID="Dienstverwaltung_Archiv_1_V1"
        text="Im Archiv können erfasste Dienstberichte eingesehen werden. Abfragen können dabei auf einen bestimmten Zeitraum begrenzt werden."
      >
      </TippCard>
    </section>
    <section>
      <template>
        <v-container>
          <v-row>
            <v-col cols="12">
              <Subheader
                icon="mdi-archive"
                title="Abgeschlossene Dienstberichte"
              >
              </Subheader>
              <div class="mt-7">
                <v-card :loading="loading">
                  <v-card-text>
                    <v-container class="mb-n10">
                      <v-form
                        @submit.prevent="startQuery"
                        v-model="formIsValid"
                        ref="form"
                      >
                        <v-row>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.start"
                              type="date"
                              label="Startdatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :disabled="loading"
                              clearable
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="3">
                            <v-text-field
                              v-model="query.range.end"
                              type="date"
                              label="Enddatum"
                              hint="DD.MM.YYYY"
                              outlined
                              :rules="[rules.required]"
                              required
                              :disabled="loading"
                              prepend-inner-icon="mdi-calendar-start"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="12" md="6">
                            <v-select
                              v-model="query.status"
                              :items="statusValues"
                              label="Berichtsstatus"
                              outlined
                              multiple
                              required
                              item-text="status"
                              item-value="value"
                              :disabled="loading"
                              prepend-inner-icon="mdi-list-status"
                              hide-details="auto"
                            >
                              <template v-slot:selection="data">
                                <report-status-chip
                                  :status="data.item.value"
                                  small
                                  @click="toggle"
                                ></report-status-chip>
                              </template> </v-select
                          ></v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      depressed
                      color="success"
                      :disabled="loading"
                      @click="startQuery()"
                      class="mr-5"
                      >Abfrage starten</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </div>
              <div class="mt-7">
                <reports-data-table
                  :reports="reports"
                  reportAssignedToFilter="all"
                ></reports-data-table>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </template>
    </section>
    <section v-if="checkIfSupport">
      <support-tools :sources="code"></support-tools>
    </section>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
import Subheader from "@/components/_systemwide/Subheader.vue";
import SupportTools from "@/components/_system/helpers/SupportTools.vue";
import TippCard from "@/components/_systemwide/Tipp_Card.vue";
import ReportsDataTable from "@/components/duty-management/ReportsDataTable.vue";
import ReportStatusChip from "@/components/duty-management/ReportStatusChip.vue";
import { DUTY_MANAGEMENT } from "@/store/modules.js";
import { GET_ARCHIVED_REPORTS } from "@/store/action-types.js";
export default {
  name: "duty-management-archive",
  components: {
    NavigationBar,
    Subheader,
    TippCard,
    ReportsDataTable,
    ReportStatusChip,
    SupportTools,
  },
  data() {
    return {
      query: {
        range: {
          start: null,
          end: null,
        },
        status: ["closed"],
        types: [],
        groups: [],
      },
      formIsValid: false,
      loading: true,

      rules: {
        required: (value) => !!value || "Feld ist erforderlich.",
      },
      statusValues: [
        {
          icon: "mdi-history",
          value: "open",
          color: "red",
          status: "Offen",
        },
        {
          icon: "mdi-pencil-box-multiple",
          value: "inProgress",
          color: "orange",
          status: "In Bearbeitung",
        },
        {
          icon: "mdi-check-all",
          value: "toBeReviewed",
          color: "purple",
          status: "Zu prüfen",
        },
        {
          icon: "mdi-archive-check",
          value: "closed",
          color: "green",
          status: "Geschlossen",
        },
      ],
    };
  },
  computed: {
    // reportTypes() {
    //   return this.$store.state.dutyManagement.reportTypes;
    // },
    archive() {
      return this.$store.state.dutyManagement.archivedReports;
    },
    archiveMatched() {
      const b = this.archive.map((obj) => ({
        ...obj,
        groupBy: this.groupByDateText(obj.duration.start.dateFormat),
      }));
      return b;
    },
    checkIfSupport() {
      return this.$store.getters["organization/checkIfSupport"];
    },
    code() {
      return [
        { title: "archiveMatched", data: this.archiveMatched },
        { title: "query", data: this.query },
      ];
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.query.range.end = new Date().toISOString().substr(0, 10);
      // this.$store
      //   .dispatch(`${SCHEDULING}${GET_EVENT_TYPES}`, {
      //     organizationId: this.$route.params.organizationId,
      //   })
      //   // .then(() => {
      //   //   return this.$store.dispatch(`${ADMIN}${GET_USER_GROUPS}`, {
      //   //     organizationId: this.$route.params.organizationId,
      //   //   });
      //   // })
      //   // .then(() => {
      //   //   return (this.query.groups = this.userGroups.map((obj) => obj.id));
      //   // })
      //   // .then(() => {
      //   //   return (this.query.types = this.eventTypes.map((obj) => obj.meta.id));
      //   // })
      //   .then(() => {
      //     this.loading = false;
      //   });
      this.loading = false;
    },
    validate() {
      this.$refs.form.validate();
    },
    startQuery() {
      this.validate();
      if (this.formIsValid) {
        if (this.query.range.start == "") {
          this.query.range.start = null;
        }
        this.$store.dispatch(`${DUTY_MANAGEMENT}${GET_ARCHIVED_REPORTS}`, {
          organizationId: this.$route.params.organizationId,
          query: {
            range: {
              start: this.query.range.start,
              end: this.query.range.end,
            },
            // types: this.query.types,
            // groups: this.query.groups,
            status: this.query.status,
          },
        });
      } else {
        alert("Fehler: Es ist ein Enddatum für die Abfrage erforderlich.");
      }
    },
    groupByDateText(date) {
      const month = ("0" + (date.getMonth() + 1)).slice(-2);
      const year = date.getFullYear();
      const monthLong = date.toLocaleString("de-DE", { month: "long" });
      return year + "-" + month + "_" + monthLong + " " + year;
    },

    routeDetails(item) {
      this.$router.push({
        name: "scheduling-event-details-tab-general",
        params: {
          organizationId: this.$route.params.organizationId,
          itemId: item.meta.id,
        },
      });
    },
  },
};
</script>

<style></style>
